import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { H4 } from 'components/Atom/Typo';

const Component = styled.div`
  ${H4} {
    font-size: 14px;
    line-height: 1.29;
  }
`;

interface TitleProps {
  children?: ReactNode;
}

const SubTitle = ({ children }: TitleProps) => {
  return (
    <Component>
      <H4>{children}</H4>
    </Component>
  );
};

export default SubTitle;
