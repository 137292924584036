import React from 'react';
import styled from '@emotion/styled';
import LinkButton from 'components/Atom/Link';
import { IconArrowRight } from 'components/Atom/Icon';
import { P3 } from 'components/Atom/Typo';

const Component = styled.div`
  height: 20px;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 100%;
    svg {
      width: 16px;
      margin-left: 8px;
      path {
        fill: ${(props) => props.theme.colors.grey500};
      }
    }
  }

  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  }
`;

interface DetailButton {
  to: string;
}

const DetailButton = ({ to }: DetailButton) => {
  return (
    <Component className="voda_detail_button">
      <LinkButton to={to}>
        <P3>자세히 보기</P3>
        <IconArrowRight />
      </LinkButton>
    </Component>
  );
};

export default DetailButton;
