import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { P5 } from 'components/Atom/Typo';
import Button from 'components/Atom/Button';

const tongtong = keyframes`
  0% {
    top: 4px;
    opacity: 0;
  }
  40% {
    opacity: 1;
    top: 10px;
    /* opacity: 0; */
  }
  100% {
    top: 24px;
    opacity: 0;
  }
`;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 64px; */
  position: absolute;
  bottom: 80px;
  ${P5} {
    color: ${(props) => props.theme.colors.grey500};
  }
`;

const Mouse = styled(Button)`
  position: relative;
  width: 20px;
  height: 33px;
  padding: 4px 8px;
  border-radius: 19px;

  border: solid 1px ${(props) => props.theme.colors.grey500};
  background-color: white;
  margin-bottom: 8px;
`;

const Dot = styled.div`
  position: absolute;
  cursor: pointer;
  transform: translate(-50%, 0%);

  left: 50%;

  /* -webkit-animation: ${tongtong} 3s cubic-bezier(1, 0, 0, 1) infinite; */
  animation: ${tongtong} 2s cubic-bezier(1, 0, 0.805, 0.66) infinite;

  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.purple3};
`;

interface ScrollLabelProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ScrollLabel = ({ onClick }: ScrollLabelProps) => {
  return (
    <Component>
      <Mouse onClick={onClick}>
        <Dot />
      </Mouse>
      <P5>SCROLL DOWN</P5>
    </Component>
  );
};

export default ScrollLabel;
