import React from 'react';
import styled from '@emotion/styled';
import FunctionDesc from 'components/Molecule/FunctionDesc';

const Component = styled.div`
  width: 100%;
  height: 288px;
  background-color: ${(props) => props.theme.colors.grey100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Desc = styled(FunctionDesc)`
  line-height: 1.6;
`;

const WhatProblem = () => {
  return (
    <Component>
      <Desc
        title="우리는 함께 고민합니다."
        desc="사이트 방문자가 우리 웹 사이트를 잘 쓰고 있는걸까?\n데이터를 통해 사이트 운영 힌트를 얻을 순 없을까?\n디지털 트랜스포메이션(DX) 시대에 알맞은 웹 분석도구는 어떤 걸까?"
        isCenter
      />
    </Component>
  );
};

export default WhatProblem;
