import React from 'react';
import styled, { StyledComponent } from '@emotion/styled';

import IconCircle from 'components/Molecule/IconCircle';
import Title from 'components/Molecule/Title/EffectCardTitle';
import SubTitle from 'components/Molecule/Title/EffectCardSubTitle';
import DetailButton from 'components/Molecule/DetailButton';

const Component = styled.div`
  width: 312px;
  height: 292px;
  padding: 40px 24px 40px 24px;
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .voda_detail_button {
    margin-top: 40px;
  }
`;

const IconCircle_0 = styled(IconCircle)<{
  size: {
    width: number;
    height: number;
  };
}>`
  padding: 0px;
  svg {
    width: ${(props) => props.size.width}px;
    height: ${(props) => props.size.height}px;
  }
`;

interface EffectCardProps {
  title: string;
  subTitle: string;
  Icon: StyledComponent<
    any,
    any,
    {
      ref?: React.Ref<any> | undefined;
    }
  >;
  to: string;
  size: {
    width: number;
    height: number;
  };
}

const EffectCard = ({ size, title, subTitle, Icon, to }: EffectCardProps) => {
  return (
    <Component className="voda_effect_card">
      <IconCircle_0 Icon={Icon} size={size} />
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <DetailButton to={to} />
    </Component>
  );
};

export default EffectCard;
