import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { FluidObject } from 'gatsby-image';
import { Element } from 'react-scroll';

import Navigation from 'components/Organisms/Navigation';
import WhatProblem from 'components/Organisms/WhatProblem';
import IntroductionEffect from 'components/Organisms/IntroductionEffect';
import WhyChooseVoda from 'components/Organisms/WhyChooseVoda';
import Footer from 'components/Organisms/Footer';
import MainFunction from 'components/Organisms/Function/MainFunction';
import SiteSlogan from 'components/Organisms/SiteSlogan';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface RootTemplateProps {
  logoNerdImg: FluidObject;
  functionData: {
    isDescLeft: boolean;
    descData: {
      title: string;
      desc: string;
      to?: string;
      buttonTitle?: string;
    };
    isScroll: boolean;
    IllustImg: FluidObject;
    isIllustBackground: boolean;
    isBackGround: boolean;
    width: number;
    key: string;
    isAbsolute?: boolean;
  }[];
  lotateImg: FluidObject[];
}

const RootTemplate: FunctionComponent<RootTemplateProps> = ({
  logoNerdImg,
  functionData,
  lotateImg,
}) => {
  return (
    <Component>
      <Navigation vodaLogoImg={logoNerdImg} />
      <SiteSlogan lotateImg={lotateImg} />
      <Element name="auto_scroll">
        <WhatProblem />
      </Element>
      {functionData.map((element, index) => {
        if (index === 0) {
          return <MainFunction {...element} />;
        }
        return null;
      })}
      <IntroductionEffect />
      {functionData.map((element, index) => {
        if (index !== 0) {
          return <MainFunction {...element} />;
        }
        return null;
      })}
      <WhyChooseVoda />
      <Footer />
    </Component>
  );
};

export default RootTemplate;
