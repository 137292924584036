import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { graphql, withPrefix } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

import RootTemplate from 'components/Templates/RootTemplate';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface IndexPageProps {
  data: {
    mainImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    heatmapImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    lotateImg1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    lotateImg2: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    lotateImg3: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const IndexPage: FunctionComponent<IndexPageProps> = ({ data }) => {
  return (
    <Component>
      <Helmet>
        <title>VODA</title>
        <meta
          name="description"
          content="VODA는 당신의 특성에 맞춰 사용자를 분석하고, 당신의 목표가 이루어지도록 정보를 이끌어내는 사용자 경험 통합 데이터 분석 솔루션입니다"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content="VODA" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="<https://voda.nerdfactory.ai/>" />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta
          property="og:description"
          content="VODA는 당신의 특성에 맞춰 사용자를 분석하고, 당신의 목표가 이루어지도록 정보를 이끌어내는 사용자 경험 통합 데이터 분석 솔루션입니다"
        />
        <meta property="og:site_name" content="VODA" />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>

      <RootTemplate
        lotateImg={[
          data.lotateImg1.childImageSharp.fluid,
          data.lotateImg2.childImageSharp.fluid,
          data.lotateImg3.childImageSharp.fluid,
        ]}
        logoNerdImg={data.logoNerdFactoryImg.childImageSharp.fluid}
        functionData={[
          {
            IllustImg: data.mainImg.childImageSharp.fluid,
            descData: {
              title: '고민 끝에\\n‘당신에게만 집중’하는\\n분석 솔루션',
              desc: '포괄적인 사용자 분석, 한번에 많은 정보를 확인\\n할 수 있지만 과연 당신의 질문에 명확한 해답이\\n되었을까요? VODA는 당신의 특성에 맞춰 사용자를\\n분석하고, 당신의 목표가 이루어지도록 정보를\\n이끌어냅니다. VODA는 넘쳐나는 데이터 중 당신이\\n궁극적으로 원하던 데이터를 찾을 수 있도록\\n‘맞춤형 서비스’를 제공합니다.',
            },
            isBackGround: false,
            isIllustBackground: true,
            isDescLeft: true,
            isScroll: true,
            width: 648,
            key: '0',
          },
          {
            IllustImg: data.heatmapImg.childImageSharp.fluid,
            descData: {
              title: 'VODA 쉬운\\n사용자 행동 이해',
              desc: '사용자가 화면의 어떤 부분에 관심이 많을까요?\\n어떤 흐름으로 페이지를 이용할까요?\\n스크롤을 내리다 어떤 화면을 주목했을까요?\\n더이상 사용자를 추측하지 마세요.\\nVODA가 사용자를 들여다볼 수 있게 도와드립니다.',
              buttonTitle: 'VODA기능 더 알아보기',
              to: 'functions/uxAnalysis',
            },
            isBackGround: false,
            isIllustBackground: false,
            isDescLeft: true,
            isScroll: false,
            isAbsolute: true,
            width: 648,
            key: '2',
          },
        ]}
      />
    </Component>
  );
};

export default IndexPage;

export const queryData = graphql`
  query {
    mainImg: file(name: { eq: "img-main" }) {
      childImageSharp {
        fluid(maxWidth: 852, fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heatmapImg: file(name: { eq: "img-main-ux" }) {
      childImageSharp {
        fluid(maxWidth: 912, maxHeight: 929, fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lotateImg1: file(name: { eq: "main_illust___2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lotateImg2: file(name: { eq: "main_illust___3" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lotateImg3: file(name: { eq: "img_main_illust___4" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
