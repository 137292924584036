import React from 'react';
import styled from '@emotion/styled';
import { H3, P2 } from 'components/Atom/Typo';
import { css } from '@emotion/react';

const Component = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

interface MainRankLabelProps {
  className?: string;
  isHighlight: boolean;
  title: string;
  desc: string;
  rank: number;
}

const Circle = styled.div<{ isHighlight: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${H3} {
    color: white;
  }

  ${(props) => {
    if (props.isHighlight) {
      return css`
        background-color: ${props.theme.colors.purple4};
      `;
    }
    return css`
      background-color: ${props.theme.colors.purple1};
    `;
  }}
`;

const TitleWrapper = styled.div<{ isHighlight: boolean }>`
  display: flex;
  flex-direction: column;
  ${H3} {
    margin-bottom: 8px;
  }

  ${(props) => {
    if (props.isHighlight) {
      return css``;
    }
    return css`
      ${H3} {
        color: ${props.theme.colors.grey400};
      }
      ${P2} {
        color: ${props.theme.colors.grey400};
      }
    `;
  }}
`;

const MainRankLabel = ({
  className,
  title,
  desc,
  isHighlight,
  rank,
}: MainRankLabelProps) => {
  return (
    <Component className={className}>
      <Circle isHighlight={isHighlight}>
        <H3>{rank}</H3>
      </Circle>
      <TitleWrapper isHighlight={isHighlight}>
        <H3>{title}</H3>
        <P2>{desc}</P2>
      </TitleWrapper>
    </Component>
  );
};

export default MainRankLabel;
