import React, { useState } from 'react';
import styled from '@emotion/styled';
import { scroller, animateScroll, Events } from 'react-scroll';

import MainTitle from 'components/Organisms/MainTitle';
import MainRankLabel from 'components/Molecule/Label/MainRankLabel';

import { H1 } from 'components/Atom/Typo';
import { FluidObject } from 'gatsby-image';
import Image from 'components/Molecule/Image';
import { css, keyframes } from '@emotion/react';
import ScrollLabel from 'components/Molecule/Label/ScrollLabel';
import { useEffect } from 'react';

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
opacity: 1;
}
`;
const fadeOut = keyframes`
from {
opacity: 1;
}
to {
  opacity: 0;
}
`;

const Component = styled.div`
  width: 100%;
  height: calc(100vh - 68px);
  /* padding-bottom: 40px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PageTitle = styled.div<{ isOn: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  align-items: center;
  justify-content: center;
  /* margin-top: 40px; */

  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  ${(props) => {
    if (props.isOn) {
      return css`
        animation-name: ${fadeIn};
      `;
    }
    return css`
      animation-name: ${fadeOut};
    `;
  }}
`;

const RandomBox = styled.div<{ isOn: boolean }>`
  width: 100%;
  padding: 80px 60px;
  display: flex;
  justify-content: center;

  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  ${(props) => {
    if (props.isOn) {
      return css`
        animation-name: ${fadeIn};
      `;
    }
    return css`
      animation-name: ${fadeOut};
    `;
  }}
`;

const ImgBox = styled.div`
  width: 760px;
  height: 479px;
  background-color: black;
  margin-right: 136px;
  border-radius: 20px;
`;

const DescBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  ${H1} {
    line-height: 1;
    font-size: 40px;
    &:last-child {
      font-size: 72px;
      margin-top: 16px;
    }
  }
  margin-bottom: 72px;
`;

interface SiteSloganProps {
  lotateImg: FluidObject[];
}

const SiteSlogan = ({ lotateImg }: SiteSloganProps) => {
  const [viewID, setViewID] = useState(0);
  let isScroll = false;
  // useEffect(() => {
  //   setInterval(() => {
  //     setViewID((prev) => {
  //       if (prev === 3) {
  //         return 0;
  //       } else {
  //         return prev + 1;
  //       }
  //     });
  //   }, 3000);
  // }, []);

  function listenToWheel(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    return false;
  }

  const onScroll = (event: Event) => {
    if (window.scrollY > 0 && !isScroll && window.scrollY < 30) {
      isScroll = true;

      animateScroll.scrollTo(865, {
        duration: 1000,
        delay: 0,
        smooth: true,
        offset: 0,
      });
    }
    if (window.scrollY === 0) {
      isScroll = false;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll, {
      capture: true,
    });

    Events.scrollEvent.register('begin', function () {
      console.log('begin');
      window.addEventListener('wheel', listenToWheel, {
        capture: true,
        passive: false,
      });
    });

    Events.scrollEvent.register('end', function () {
      console.log('end');
      window.removeEventListener('wheel', listenToWheel, true);
    });

    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, []);
  return (
    <Component>
      {viewID === 0 ? (
        <PageTitle isOn={viewID === 0}>
          <MainTitle />
        </PageTitle>
      ) : (
        <RandomBox isOn={viewID !== 0}>
          <ImgBox>
            <Image img={lotateImg[viewID - 1]} />
          </ImgBox>
          <DescBox>
            <TitleWrapper>
              <H1>사용자를 바라보는 시선</H1>
              <H1>VODA</H1>
            </TitleWrapper>
            <MainRankLabel
              title="미래를 마주하다"
              desc="예측된 미래로 VODA 확실한 의사결정"
              rank={1}
              isHighlight={viewID === 1}
            />
            <MainRankLabel
              title="시그널을 분석하다"
              desc="VODA 쉬운 사용자의 행동 이해"
              rank={2}
              isHighlight={viewID === 2}
            />
            <MainRankLabel
              title="당신에게만 집중하다"
              desc="사용자 맞춤형 데이터 서비스 제공"
              rank={3}
              isHighlight={viewID === 3}
            />
          </DescBox>
        </RandomBox>
      )}
      <ScrollLabel
        onClick={() => {
          animateScroll.scrollTo(865, {
            duration: 1000,
            delay: 0,
            smooth: true,
            offset: 0,
          });
        }}
      />
    </Component>
  );
};

export default SiteSlogan;
