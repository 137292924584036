import React from 'react';
import styled from '@emotion/styled';
import { H1, P1 } from 'components/Atom/Typo';
import MoreButton from 'components/Molecule/MoreButton';
import ScrollLabel from 'components/Molecule/Label/ScrollLabel';

const Copmponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${H1} {
    font-size: 48px;
    color: ${(props) => props.theme.colors.grey900};
    line-height: 1;

    &:last-child {
      line-height: 1;
      font-size: 88px;
      margin-top: 16px;
    }
  } ;
`;

const MainTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubTitleWrapper = styled.div`
  margin-top: 80px;
  /* margin-bottom: 80px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  ${P1} {
    line-height: 1.6;
  }
`;

const MainTitle = () => {
  return (
    <Copmponent>
      <MainTitleWrapper>
        <H1>사용자를 바라보는 시선</H1>
        <H1>VODA</H1>
      </MainTitleWrapper>
      <SubTitleWrapper>
        <P1>
          시그널을 분석합니다. <br />
          데이터를 쌓아갑니다.
        </P1>
      </SubTitleWrapper>
      {/* <MoreButton
        isBold
        isBackGround
        isExternal
        to="http://analytics-demo.nerdfactory.ai"
        title={'VODA 체험해보기'}
      /> */}
    </Copmponent>
  );
};

export default MainTitle;
