import React from 'react';
import styled from '@emotion/styled';
import TemplateTitle from 'components/Molecule/Title/TemplateTitle';
import EffectCard from 'components/Organisms/Card/EffectCard';
import {
  IconArrowRight,
  IconFaces,
  IconThumb,
  IconWorldWide,
} from 'components/Atom/Icon';

const Component = styled.div`
  width: 100%;
  /* height: 612px; */
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .voda_effect_card {
    margin-right: 24px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;

const IntroductionEffect = () => {
  return (
    <Component>
      <Header>
        <TemplateTitle>VODA 도입효과</TemplateTitle>
      </Header>
      <Body>
        <EffectCard
          title="정부/공공기관 도입효과"
          subTitle="국민 만족도 UP, 운영예산 DOWN"
          Icon={IconThumb}
          to="apply/agency"
          size={{
            width: 36,
            height: 42,
          }}
        />
        <EffectCard
          title="기업 홈페이지 도입효과"
          subTitle="더 크고 넓은 세계로 뻗어 나갈 수 있도록!"
          Icon={IconWorldWide}
          to="apply/company"
          size={{
            width: 42,
            height: 42,
          }}
        />
        <EffectCard
          title="온라인 쇼핑몰 도입효과"
          subTitle="당신이 추구하는 아이덴티티를 실현합니다"
          Icon={IconFaces}
          to="apply/mall"
          size={{
            width: 54.2,
            height: 42.2,
          }}
        />
      </Body>
    </Component>
  );
};

export default IntroductionEffect;
