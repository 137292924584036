import React from 'react';
import styled from '@emotion/styled';
import { H2, P1, P3 } from 'components/Atom/Typo';
import { css } from '@emotion/react';
import MoreButton from '../MoreButton';

const Component = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  .voda_more_info_button {
    margin-top: 40px;
  }
`;

const TitleWrapper = styled.div<{ isCenter: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 24px;

  ${(props) => {
    if (props.isCenter) {
      return css`
        align-items: center;
      `;
    }
    return css`
      align-items: flex-start;
    `;
  }}

  ${H2} {
    font-size: 28px;
    line-height: 1.43;
  }
`;

const DescWrapper = styled.div<{ isCenter: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;
  ${(props) => {
    if (props.isCenter) {
      return css`
        align-items: center;
      `;
    }
    return css`
      align-items: flex-start;
    `;
  }}

  ${P1} {
    color: ${(props) => props.theme.colors.grey600};
    line-height: 1.75;
    word-break: keep-all;
  }
`;

interface DescProps {
  title: string;
  desc: string;
  isCenter: boolean;
  to?: string;
  buttonTitle?: string;
  className?: string;
}

const FunctionDesc = ({
  title,
  desc,
  isCenter,
  to,
  buttonTitle,
  className,
}: DescProps) => {
  return (
    <Component className={className}>
      <TitleWrapper isCenter={isCenter}>
        {title.split('\\n').map((element) => {
          return <H2 key={element}>{element}</H2>;
        })}
      </TitleWrapper>
      <DescWrapper isCenter={isCenter}>
        {desc.split('\\n').map((element) => {
          return <P1 key={element}>{element}</P1>;
        })}
      </DescWrapper>
      {to && buttonTitle ? <MoreButton to={to} title={buttonTitle} /> : null}
    </Component>
  );
};

export default FunctionDesc;
