import React, { Children, ReactNode } from 'react';
import styled from '@emotion/styled';
import { P3 } from 'components/Atom/Typo';

const Component = styled.div`
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
    line-height: 1.29;
  }
  margin-top: 24px;
  margin-bottom: 8px;
`;

interface TitleProps {
  children?: ReactNode;
}

const Title = ({ children }: TitleProps) => {
  return (
    <Component>
      <P3>{children}</P3>
    </Component>
  );
};

export default Title;
